body {
  font-family: 'Trebuchet MS', sans-serif;
  background-color: #111111;
  color: #DDDDDD;
}

.name-header {
  font-size: 40px;
  text-align: center;
  margin: 30px;
}

.App {
  margin: 30px;
  margin: 0;
  width: 50%;
  position: absolute;
  left: 25%;
}